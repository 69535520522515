import {
  App,
  assertEnvVar,
  getFeedbackMailto,
  H5GroveProvider,
} from '@h5web/app';
import { useMemo } from 'react';

import { useQueryParam } from './utils';

const URL = import.meta.env.VITE_H5GROVE_URL || '';
const FEEDBACK_EMAIL = import.meta.env.VITE_FEEDBACK_EMAIL || '';

function Viewer() {
  assertEnvVar(URL, 'VITE_H5GROVE_URL');
  assertEnvVar(FEEDBACK_EMAIL, 'VITE_FEEDBACK_EMAIL');

  const sessionId = useQueryParam('sessionId');
  const datafileId = useQueryParam('datafileId');
  const filepath = useQueryParam('name');
  const initialPath = useQueryParam('initialPath');
  const wide = useQueryParam('wide');

  if (!sessionId || !datafileId || !filepath) {
    throw new Error('Missing URL parameter(s)');
  }

  const axiosConfig = useMemo(
    () => ({ params: { sessionId, datafileId } }),
    [sessionId, datafileId],
  );

  return (
    <H5GroveProvider url={URL} filepath={filepath} axiosConfig={axiosConfig}>
      <App
        initialPath={initialPath || undefined}
        sidebarOpen={wide === null}
        getFeedbackURL={(context) => getFeedbackMailto(context, FEEDBACK_EMAIL)}
      />
    </H5GroveProvider>
  );
}

export default Viewer;
