import type { FallbackProps } from 'react-error-boundary';

import styles from './ErrorFallback.module.css';

function prepareReport(message: string): string {
  return `Hi,

I encountered the following error on Hibou:

  >> ${message}

Here is some additional context:

  - User agent: ${navigator.userAgent}
  - Location: ${window.location.href}
  - << Please provide as much information as possible to help us debug this issue (proposal, file, username, etc.) >>

Thanks,
<< Name >>`;
}

function ErrorFallback(props: FallbackProps) {
  const { error } = props;

  return (
    <div className={styles.root}>
      <p className={styles.error}>
        {error.message}. Please try again, or report the error if it persists.
      </p>
      <a
        className={styles.reportBtn}
        target="_blank"
        href={`mailto:h5web@esrf.fr?subject=[Hibou]%20Error%20report&body=${encodeURIComponent(
          prepareReport(error.message), // eslint-disable-line @typescript-eslint/no-unsafe-argument
        )}`}
        rel="noreferrer"
      >
        Report error
      </a>
    </div>
  );
}

export default ErrorFallback;
