import '@h5web/app/styles.css';
import './styles.css';

import { assertNonNull } from '@h5web/app';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import ErrorFallback from './ErrorFallback';
import Viewer from './Viewer';

const rootElem = document.querySelector('#root');
assertNonNull(rootElem);

createRoot(rootElem).render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <Viewer />
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>,
);
